import { Component, OnInit } from '@angular/core';
import {SocialMedia} from '../../models/SocialMedia';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  public socialMedia: SocialMedia[] = [
    {name: 'Instagram', icon: 'fab fa-instagram', link: 'https://www.instagram.com/_lichti96_/'},
    {name: 'Facebook', icon: 'fab fa-facebook-f', link: 'https://www.facebook.com/christoph.lichtenegger.96'},
    // {name: 'Youtube', icon: 'fab fa-youtube', link: 'https://www.instagram.com/_lichti96_/'},
    {name: 'Github', icon: 'fab fa-github', link: 'https://github.com/Lichti96'},
    {name: 'E-Mail', icon: 'fas fa-envelope', link: 'mailto:christoph.lichtenegger@live.at'},
  ];

  constructor() { }

  ngOnInit() {
  }

}
